import React from "react";

// front frameworks
import react from "../assets/images/techstack/react.svg";
import redux from "../assets/images/techstack/redux.svg";
import gatsby from "../assets/images/techstack/gatsby.svg";
// backend frameworks
import nodejs from "../assets/images/techstack/nodejs.svg";
import django from "../assets/images/techstack/django-icon.svg";
import flask from "../assets/images/techstack/flask.svg";
import laravel from "../assets/images/techstack/laravel.svg";
// databases
import mongodb from "../assets/images/techstack/mongodb.svg";
import postgresql from "../assets/images/techstack/postgresql.svg";
import mysql from "../assets/images/techstack/mysql.svg";
import firebase from "../assets/images/techstack/firebase.svg";
// mobile
import android from "../assets/images/techstack/android.svg";
import ios from "../assets//images/techstack/ios.svg";
// cloud
import aws from "../assets/images/techstack/aws.svg";
import googleCloud from "../assets/images/techstack/google-cloud.svg";
import azure from "../assets/images/techstack/microsoft-azure.svg";
// cms
import strapi from "../assets/images/techstack/strapi.svg";
import wordpress from "../assets/images/techstack/wordpress-icon-alt.svg";
// payment gateways
import stripe from "../assets/images/techstack/stripe.svg";
// langs
import js from "../assets/images/techstack/javascript.svg";
import python from "../assets/images/techstack/python.svg";
import java from "../assets/images/techstack/java.svg";
import php from "../assets/images/techstack/php.svg";
import typeScript from "../assets/images/techstack/typescript-icon.svg";
import graphql from "../assets/images/techstack/graphql.svg";
// IaaS
import terraform from "../assets/images/techstack/terraform.svg";
// DevOps tools
import docker from "../assets/images/techstack//docker-icon.svg";
import kubernetes from "../assets/images/techstack/kubernetes.svg";

// AI & ML
import tensorflow from "../assets/images/techstack/tensorflow.svg";
import pytorch from "../assets/images/techstack/pytorch-icon.svg";
import jupyter from "../assets/images/techstack/jupyter.svg";

const TechStack = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row p-1">
          <div className="col-lg-12">
            <div className="section-title text-center pb-3">
              <h2 className="title">Our Tech Stack</h2>
            </div>
          </div>
        </div>
        <ul className="brand-style-2">
          <li>
            <img className="techstack-logo" src={js} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={python} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={java} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={php} alt="Logo Images" />
          </li>
          <li>
            <img
              className="techstack-logo"
              src={typeScript}
              alt="Logo Images"
            />
          </li>
          <li>
            <img className="techstack-logo" src={graphql} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={react} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={redux} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={gatsby} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={nodejs} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={django} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={flask} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={laravel} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={mongodb} alt="Logo Images" />
          </li>
          <li>
            <img
              className="techstack-logo"
              src={postgresql}
              alt="Logo Images"
            />
          </li>
          <li>
            <img className="techstack-logo" src={mysql} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={firebase} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={android} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={ios} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={aws} alt="Logo Images" />
          </li>
          <li>
            <img
              className="techstack-logo"
              src={googleCloud}
              alt="Logo Images"
            />
          </li>
          <li>
            <img className="techstack-logo" src={azure} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={strapi} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={wordpress} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={stripe} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={terraform} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={docker} alt="Logo Images" />
          </li>
          <li>
            <img
              className="techstack-logo"
              src={kubernetes}
              alt="Logo Images"
            />
          </li>
          {/* AI Development */}
          <li>
            <img
              className="techstack-logo"
              src={tensorflow}
              alt="Logo Images"
            />
          </li>
          <li>
            <img className="techstack-logo" src={pytorch} alt="Logo Images" />
          </li>
          <li>
            <img className="techstack-logo" src={jupyter} alt="Logo Images" />
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default TechStack;
