import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. We will contact you soon.
    </p>
  );
};
function ContactForm({ props }) {
  const [result, setShowResult] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_ML_API_ENDPOINT}/prod/email`, {
        name: e.target?.elements.fullname.value,
        email: e.target?.elements.email.value,
        body: e.target?.elements.message.value,
        subject: e.target?.elements.subject.value,
        phone: e.target?.elements.phone.value,
      })
      .then((res) => {
        console.log(res);
        setShowResult(true);
        setEnableSubmit(false);
        e.target.reset();
      })
      .catch((e) => {
        e.target.reset();
      });
  };

  function onChange(value) {
    if (value) {
      setEnableSubmit(true);
    }
  }

  return (
    <form action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input type="text" name="fullname" placeholder="Your Name" required />
      </div>
      <div className="rn-form-group">
        <input type="email" name="email" placeholder="Your Email" required />
      </div>
      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="Phone Number" required />
      </div>
      <div className="rn-form-group">
        <input type="text" name="subject" placeholder="Subject" required />
      </div>
      <div className="rn-form-group">
        <textarea name="message" placeholder="Your Message" required></textarea>
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_ML_RE_CAPTCHA_SITE_KEY}
        onChange={onChange}
      />
      <div className="rn-form-group" style={{ marginTop: "20px" }}>
        <button
          disabled={!enableSubmit}
          className={
            enableSubmit
              ? "rn-button-style--2 btn-solid"
              : "rn-button-style--2 btn-disabled"
          }
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Submit Now
        </button>
      </div>
      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
