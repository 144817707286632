import React from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import ScrollToTop from "react-scroll-up";
import Footer from "../component/footer/Footer";

import { FiChevronUp } from "react-icons/fi";
import HeaderSix from "../component/header/HeaderSix";
import moment from "moment-timezone";

const PrivacyPolicy = () => {
  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Privacy Policy" />
      <HeaderSix
        menuTrigger={menuTrigger}
        closeMenuTrigger={CLoseMenuTrigger}
      />
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image--1"
        data-black-overlay="7"
      />
      {/* <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Header</h2>
                <p>Paragraph</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End Breadcrump Area */}

      {/* Start Privacy Policy Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner privacy-policy">
                  <h2>Privacy & Policy</h2>
                  {/* <span>Last Updated: {moment().format("YYYY-MM-DD")}</span> */}
                  <p className="subtitle">Introduction</p>
                  <p>
                    Welcome to Mind Logics, a New Zealand-based software company
                    dedicated to providing innovative solutions. At MindLogics,
                    we are committed to protecting the privacy and security of
                    your personal information. This Privacy Policy outlines how
                    we collect, use, disclose, and protect your information when
                    you use our website, products, and services.
                  </p>

                  <p className="subtitle">Information We Collect</p>
                  <div>
                    <p>
                      We may collect personal information directly from you or
                      indirectly through your use of our website, products, and
                      services. The types of information we may collect include:
                    </p>
                    <ol>
                      <li>
                        Personal Information: such as your name, email address,
                        postal address, phone number, and other contact details.
                      </li>
                      <li>
                        Account Information: such as your username, password,
                        and other authentication information.
                      </li>
                      <li>
                        Payment Information: such as credit card details or
                        other payment information.
                      </li>
                      <li>
                        Usage Information: such as information about how you use
                        our website, products, and services, including your IP
                        address, device information, and browsing activity.
                      </li>
                      <li>
                        Feedback and Communications: such as any feedback,
                        questions, or inquiries you submit to us.
                      </li>
                    </ol>
                  </div>
                  <p className="subtitle">Changes to this Privacy Policy</p>
                  <p>
                    We may update this Privacy Policy from time to time by
                    posting a new version on our website. We will notify you of
                    any material changes to this Privacy Policy by email or
                    through a notice on our website.
                  </p>
                  <p className="subtitle">Contact Us</p>
                  <p>
                    If you have any questions or concerns about this Privacy
                    Policy or our privacy practices, please contact us at{" "}
                    <i>hello@mindlogics.co.nz</i>.
                  </p>
                  <p className="subtitle">Effective Date</p>
                  <span>
                    This Privacy Policy is effective as of{" "}
                    {moment().format("YYYY-MM-DD")}.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Start Privacy Policy Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
