// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// layouts
import HomeParticles from "./home/HomeParticles";
import BlogDetails from "./elements/BlogDetails";
import PortfolioDetails from "./elements/PortfolioDetails";
import TeamDetails from "./elements/TeamDetails";
import PrivacyPolicy from "./elements/PrivacyPolicy";
import TermCondition from "./elements/TermsCondition";
import Team from "./elements/Team";

// Element Layout
import error404 from "./elements/error404";

// Blocks Layout
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={HomeParticles}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-details/:id`}
              component={PortfolioDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/team-details`}
              component={TeamDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/privacy-policy`}
              component={PrivacyPolicy}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/terms-conditions`}
              component={TermCondition}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/team`}
              component={Team}
            />
            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/team-details/:id`}
              component={TeamDetails}
            /> */}
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
