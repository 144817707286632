import React from "react";
import Scrollspy from "react-scrollspy";
import MLlogoLight from "../../assets/images/logo/ml-logo-light.png";
import MLlogoDark from "../../assets/images/logo/ml-logo-dark.png";
import { FiX, FiMenu } from "react-icons/fi";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const HeaderSix = ({ menuTrigger, closeMenuTrigger }) => {
  return (
    <header className="header-area formobile-menu header--fixed default-color">
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <a href="/">
              <img className="logo-1" src={MLlogoLight} alt="Logo Images" />
              <img className="logo-2" src={MLlogoDark} alt="Logo Images" />
            </a>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <Scrollspy
              className="mainmenu"
              items={[
                "home",
                "service",
                "about",
                "portfolio",
                "team",
                "testimonial",
                "contact",
              ]}
              currentClassName="is-current"
              offset={-200}
            >
              <li onClick={closeMenuTrigger}>
                <Link to="/">Home</Link>
              </li>
              <li onClick={closeMenuTrigger}>
                <HashLink to="/#service">Services</HashLink>
              </li>
              <li onClick={closeMenuTrigger}>
                <HashLink to="/#about">About</HashLink>
              </li>
              <li onClick={closeMenuTrigger}>
                <HashLink to="/#portfolio">Portfolio</HashLink>
              </li>
              <li onClick={closeMenuTrigger}>
                <HashLink to="/team">Team</HashLink>
              </li>
              <li onClick={closeMenuTrigger}>
                <HashLink to="/#testimonial">Testimonial</HashLink>
              </li>
              <li onClick={closeMenuTrigger}>
                <HashLink to="/#contact">Contact</HashLink>
              </li>
            </Scrollspy>
          </nav>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={menuTrigger} className="menutrigger text-white">
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={closeMenuTrigger} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderSix;
