import React from "react";
import data from "../../resources/portfolios.json";
import _ from "lodash";
import Slider from "react-slick";
import { slickDot } from "../../page-demo/script";
import { Link } from "react-router-dom";

const PortfolioOne = () => {
  return (
    <Slider {...slickDot}>
      {_.map(data.portfolios, (value, index) => (
        <div className="portfolio" key={index}>
          <div className="thumbnail-inner">
            <div className={`thumbnail ${value.image}`} />
            <div className={`bg-blr-image ${value.image}`} />
          </div>
          <div className="content">
            <div className="inner">
              <p>{value.category}</p>
              <h4>
                <Link
                  to={{
                    pathname: `/portfolio-details/${value.slug}`,
                  }}
                >
                  {value.title}
                </Link>
              </h4>
              <div className="portfolio-button">
                <Link
                  className="rn-btn"
                  to={{
                    pathname: `/portfolio-details/${value.slug}`,
                  }}
                >
                  See More
                </Link>
              </div>
            </div>
          </div>
          <Link
            to={{
              pathname: `/portfolio-details/${value.slug}`,
            }}
          >
            {value.title}
          </Link>
        </div>
      ))}
    </Slider>
  );
};
export default PortfolioOne;
