import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import testimonialImg1 from "../assets/images/client/testimonial-1.png";
import testimonialImg2 from "../assets/images/client/testimonial-2.png";
import testimonialImg4 from "../assets/images/client/shashi.jpg";

class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Mind Logics created a user-friendly Website that perfectly
                      fit our vision. Their team was responsive, insightful, and
                      delivered a seamless integration. Looking forward to
                      future projects together!
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Jose Sanchez </span> - DIRECTOR, TANIWHA GUIDING
                      SERVICES LIMITED.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      The team at Mind Logic is epic. They took up our complex
                      challenge and delivered a simple and beautiful surf
                      reporting web app in the most secure, streamlined, and
                      user-friendly way. Highly recommended and very easy to
                      work with them.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Ben Barr</span> - CEO, GOOD SURF NOW.
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Working with Mind Logics was a fantastic experience! They
                      developed a website for us to sell our products,
                      transforming our sales process and significantly boosting
                      both efficiency and client satisfaction. Their service is
                      truly top-notch.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>W. Sashi </span> - CEO, HYBRID CORE.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              {/* Testimonials Images List */}
              <TabList className="testimonial-thumb-wrapper">
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg1} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg2} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimonialImg4} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
