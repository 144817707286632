import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderSix from "../component/header/HeaderSix";
import teamsdata from "../resources/teams.json";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import team1 from "../assets/images/team/team-img.jpg";

const TeamDetails = () => {
  const [teamData, setTeamData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      const keywords = location.pathname.split("/");
      const slug = keywords[keywords.length - 1];
      const _team = _.find(teamsdata.teamDetails, (t) => t.slug === slug);
      setTeamData(_team);
    }
  }, [location.pathname]);

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  return teamData ? (
    <React.Fragment>
      <PageHelmet pageTitle="Team Details" />
      <HeaderSix
        menuTrigger={menuTrigger}
        closeMenuTrigger={CLoseMenuTrigger}
      />
      {/* Start Team Details */}
      <div className="about-area ptb--120 bg_color--5">
        <div className="about-wrapper">
          <div className="container">
            <div className="team-details-section">
              <div className="row row--35">
                <div className="col-lg-4">
                  <div className="thumbnail team-details-image">
                    <img
                      className="w-100"
                      src={require(`../assets/images/team/${teamData.teamImages}`)}
                      alt="About Images"
                    />
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="team-details-about-section">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h2 className="title team-details-title">About Me</h2>
                        <p className="description team-details-aboutme">
                          {teamData.detailsPage.aboutme}
                        </p>
                      </div>
                      <div className="row mt--30">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="about-us-list text-justify experience-sections">
                            <h3 className="title team-details-experience-title">
                              Experinces
                            </h3>
                            <p className="team-details-experience-description">
                              {teamData.detailsPage.experience}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  ) : (
    <div />
  );
};

export default TeamDetails;
