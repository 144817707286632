import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

import { useLocation } from "react-router";
import data from "../resources/portfolios.json";
import _ from "lodash";
import ModalVideo from "react-modal-video";
import { Background } from "react-parallax";
import HeaderSix from "../component/header/HeaderSix";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";

const PortfolioDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [portfolio, setPortfolio] = useState(null);
  const [otherPortfolio, setOtherPortfolio] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      const keywords = location.pathname.split("/");
      const slug = keywords[keywords.length - 1];
      const _portfolio = _.find(data.portfolios, (p) => p.slug === slug);
      const _otherPortfolio = _.filter(data.portfolios, (p) => p.slug !== slug);
      setPortfolio(_portfolio);
      setOtherPortfolio(_otherPortfolio);
    }
  }, [location.pathname]);

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  // const openModal = () => {
  //   setIsOpen(true);
  // };

  return portfolio ? (
    <React.Fragment>
      <PageHelmet pageTitle="Portfolio Details" />
      <HeaderSix
        menuTrigger={menuTrigger}
        closeMenuTrigger={CLoseMenuTrigger}
      />
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  {portfolio.singlePage.bannerTagline}
                </h2>
                {/* <p>{portfolio.singlePage.bannerCaption}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={require(`../assets/images/portfolio/${portfolio.singlePage.portfolioImageOne}`)}
                alt="Portfolio Images"
              />
            </div>
            <div className="col-lg-6">
              <div className="portfolio-details">
                <div className="inner" style={{ padding: "0px" }}>
                  <h2>{portfolio.singlePage.contentTagLine}</h2>
                  <div className="text-justify">
                    <p className="subtitle">
                      {portfolio.singlePage.descriptionOne}
                    </p>
                    <p>{portfolio.singlePage.descriptionTwo}</p>
                  </div>

                  <div className="portfolio-view-list d-flex flex-wrap">
                    <div className="port-view">
                      <span>Technologies</span>
                      <h4>{portfolio.singlePage.techStack}</h4>
                    </div>

                    <div className="port-view">
                      <span>Project Types</span>
                      <h4>{portfolio.singlePage.projectType}</h4>
                    </div>

                    {/* <div className="port-view">
                      <span>Program</span>
                      <h4>{portfolio.singlePage.program}</h4>
                    </div> */}
                  </div>
                </div>

                {/* Video Section */}
                {/* <div className="portfolio-thumb-inner">
                  <div className="thumb position-relative mb--30">
                    <img
                      src={require("../assets/images/portfolio/portfolio-big-03.jpg")}
                      alt="Portfolio Images"
                    />
                    <ModalVideo
                      channel="youtube"
                      isOpen={isOpen}
                      videoId="ZOoVOfieAF8"
                      onClose={() => setIsOpen(false)}
                    />
                    <button
                      className="video-popup position-top-center"
                      onClick={openModal}
                    >
                      <span className="play-icon"></span>
                    </button>
                  </div>
                  <div className="thumb mb--30">
                    <img
                      src={require("../assets/images/portfolio/portfolio-big-03.jpg")}
                      alt="Portfolio Images"
                    />
                  </div>

                  <div className="thumb">
                    <img
                      src={require("../assets/images/portfolio/portfolio-big-03.jpg")}
                      alt="Portfolio Images"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Related Work */}

      <div className="portfolio-related-work pb--120 bg_color--1 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="theme-color font--18 fontWeight600">
                  Related Work
                </span>
                <h2>More Projects</h2>
              </div>
            </div>
          </div>
          <div className="row mt--10">
            {/* Start Single Portfolio */}
            {otherPortfolio.map((val) => (
              <div className="col-lg-4 col-md-4 col-xs-12" key={val.slug}>
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href={`/portfolio-details/${val.slug}`}>
                      <img
                        src={require(`../assets/images/portfolio/${val.singlePage.horizontalImage}`)}
                        // alt="Portfolio-images"
                      />
                    </a>
                  </div>
                  <div className="inner">
                    <h4>
                      <a href={`/portfolio-details/${val.slug}`}>{val.title}</a>
                    </h4>
                    <span className="category">{val.category}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* End Single Portfolio */}
        </div>
      </div>

      {/* End Related Work */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  ) : (
    <div />
  );
};
export default PortfolioDetails;
