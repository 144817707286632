import React from "react";
import { FaLinkedinIn, FaEnvelope } from "react-icons/fa";
import HeaderSix from "../component/header/HeaderSix";
import Footer from "../component/footer/Footer";
import PageHelmet from "../component/common/Helmet";
import { Link } from "react-router-dom";
import data from "../resources/teams.json";
import _ from "lodash";

let TeamSocialsMap = {
  FaLinkedinIn: <FaLinkedinIn />,
  FaEnvelope: <FaEnvelope />,
};

const Team = () => {
  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Team" />
      <HeaderSix
        menuTrigger={menuTrigger}
        closeMenuTrigger={CLoseMenuTrigger}
      />

      <div
        className="rn-team-area teams-section ptb--120 bg_color--1 mt-4"
        id="team"
      >
        <div className="container">
          <div className="row">
            <div className="companyDescriptionWrapper">
              <div className="section-title teams-title service-style--3 text-left mb--25 mb_sm--0">
                <h2 className="title">Skilled Team</h2>
                <p>
                  At Mind Logics, our talented team of industry leaders, skilled
                  developers, and dedicated professionals drives our success.
                  With expertise in cutting-edge technologies, our developers
                  create robust and secure software solutions, while our product
                  managers ensure these solutions align with market demands. Our
                  leadership fosters a culture of excellence and innovation, and
                  our quality assurance team upholds the highest standards of
                  performance and reliability. Supported by our exceptional
                  customer success team, we are committed to helping your
                  business thrive with innovative, tailor-made software
                  solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {_.map(data.teamDetails, (value, i) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                <div className="team">
                  <div className="thumbnail">
                    {/* <img src={value.images} alt="Blog Images" /> */}
                    <img
                      src={require(`../assets/images/team/${value.teamImages}`)}
                      alt="Team Images"
                    />
                  </div>
                  <div className="content">
                    <Link to={"#"}>
                      <h4 className="title">{value.title}</h4>
                      <p className="designation">{value.designation}</p>
                    </Link>
                    {/* Socials */}
                    <ul className="social-icon">
                      {value.socialNetwork.map((social, index) => (
                        <li key={index}>
                          <a href={`${social.url}`} target="_blank">
                            {TeamSocialsMap[social.icon]}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Team;
