import React, { Component } from "react";

// icons
import {
  FiCode,
  FiSmartphone,
  FiCpu,
  FiSearch,
  FiMonitor,
  FiRadio,
} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiCode />,
    title: "Full-Stack Engineering",
    description:
      "We specialize in creating visually compelling websites and web apps tailored to your specific requirements. We blend creativity with technical expertise for brand-focused websites.",
  },
  {
    icon: <FiRadio />,
    title: "Networking Solutions",
    description:
      "Empower your business with robust networking solutions. We design, implement, and manage secure infrastructures, including hardware installation and configuration, tailored to your specific needs for optimal performance.",
  },
  {
    icon: <FiCpu />,
    title: "AI and ML Integration",
    description:
      "Unlock the potential of your business with our AI Integration services. Harness cutting-edge technology to optimize operations and drive innovation across your organization.",
  },
  {
    icon: <FiSmartphone />,
    title: "Mobile App Development",
    description:
      "We design and build feature-rich mobile applications that bring your ideas to life. Our tailored solutions ensure smooth user experiences across various devices and platforms.",
  },

  {
    icon: <FiSearch />,
    title: "SEO",
    description:
      "We optimize your website to rank higher in search engine results, driving more traffic and potential customers to your business. Our tailored strategies enhance your online visibility and engagement.",
  },
  {
    icon: <FiMonitor />,
    title: "IT Support & Maintenance",
    description:
      "Ensure your IT infrastructure operates seamlessly with our expert support and maintenance services. Our experts provide proactive management and quick issue resolution.",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              {/* <a href="/service-details"></a> */}
              <div className="service-container-section">
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
