import React from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import ScrollToTop from "react-scroll-up";
import Footer from "../component/footer/Footer";
import HeaderSix from "../component/header/HeaderSix";

import moment from "moment-timezone";

import { FiChevronUp } from "react-icons/fi";

const TermCondition = () => {
  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Terms & Conditions" />
      <HeaderSix
        menuTrigger={menuTrigger}
        closeMenuTrigger={CLoseMenuTrigger}
      />
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image--1"
        data-black-overlay="7"
      >
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Header</h2>
                <p>Paragraph</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* End Breadcrump Area */}

      {/* Start Privacy Policy Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner privacy-policy">
                  <h2>Terms & Conditions</h2>
                  {/* <span>Last Updated: {moment().format("YYYY-MM-DD")}</span> */}
                  <p className="subtitle">Introduction</p>
                  <p>
                    Welcome to Mind Logics, a New Zealand-based software company
                    providing innovative solutions. These Terms & Conditions
                    govern your access to and use of our website, products, and
                    services. By accessing or using our website, products, or
                    services, you agree to be bound by these Terms & Conditions.
                    If you do not agree to these Terms & Conditions, please do
                    not access or use our website, products, or services.
                  </p>

                  <p className="subtitle">
                    Use of Our Website, Products, and Services
                  </p>
                  <ol>
                    <li>
                      License: Subject to these Terms & Conditions, we grant you
                      a limited, non-exclusive, non-transferable, revocable
                      license to access and use our website, products, and
                      services for your personal or internal business purposes.
                    </li>
                    <li>
                      Intellectual Property: All content on our website,
                      products, and services, including text, graphics, logos,
                      images, and software, is the property of MindLogics or its
                      licensors and is protected by intellectual property laws.
                      You may not reproduce, modify, distribute, or create
                      derivative works of any content without our prior written
                      consent.
                    </li>
                    <li>
                      Prohibited Activities: You agree not to engage in any of
                      the following activities:
                      <ul>
                        <li>Violating any applicable laws or regulations.</li>
                        <li>
                          Interfering with or disrupting the integrity or
                          performance of our website, products, or services.
                        </li>
                        <li>
                          Attempting to gain unauthorized access to our website,
                          products, or services.
                        </li>
                        <li>
                          Transmitting any viruses, malware, or other harmful
                          code.
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <p className="subtitle">Privacy</p>
                  <p>
                    Your privacy is important to us. Please refer to our Privacy
                    Policy for information on how we collect, use, disclose, and
                    protect your personal information.
                  </p>
                  <p className="subtitle">Disclaimer of Warranties</p>
                  <p>
                    OUR WEBSITE, PRODUCTS, AND SERVICES ARE PROVIDED ON AN "AS
                    IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND,
                    EITHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES,
                    INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE
                    DO NOT WARRANT THAT OUR WEBSITE, PRODUCTS, OR SERVICES WILL
                    BE UNINTERRUPTED OR ERROR-FREE.
                  </p>
                  <p className="subtitle">Limitation of Liability</p>
                  <p>
                    IN NO EVENT SHALL MINDLOGICS BE LIABLE FOR ANY INDIRECT,
                    INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
                    INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS,
                    DATA, USE, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR
                    RELATING TO YOUR USE OF OR INABILITY TO USE OUR WEBSITE,
                    PRODUCTS, OR SERVICES.
                  </p>
                  <p className="subtitle">Indemnification</p>
                  <p>
                    You agree to indemnify and hold MindLogics, its affiliates,
                    officers, directors, employees, and agents harmless from any
                    claims, damages, liabilities, and expenses (including
                    attorneys' fees) arising out of or relating to your
                    violation of these Terms & Conditions or your use of our
                    website, products, or services.
                  </p>
                  <p className="subtitle">Governing Law</p>
                  <p>
                    These Terms & Conditions shall be governed by and construed
                    in accordance with the laws of New Zealand, without regard
                    to its conflict of law principles.
                  </p>
                  <p className="subtitle">
                    Changes to these Terms & Conditions
                  </p>
                  <p>
                    We reserve the right to update or modify these Terms &
                    Conditions at any time without prior notice. By continuing
                    to access or use our website, products, or services after
                    any such changes, you agree to be bound by the revised Terms
                    & Conditions.
                  </p>
                  <p className="subtitle">Contact Us</p>
                  <p>
                    If you have any questions or concerns about these Terms &
                    Conditions, please contact us at{" "}
                    <i>hello@mindlogics.co.nz</i>.
                  </p>
                  <p className="subtitle">Effective Date</p>
                  <span>
                    This Privacy Policy is effective as of{" "}
                    {moment().format("YYYY-MM-DD")}.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Start Privacy Policy Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
};

export default TermCondition;
