import React from "react";
import ContactForm from "./ContactForm";

import contact from "../../assets/images/contact/contact-img.jpg";

//icons
import phone from "../../assets/images/contact/contact-icons/icons8-telephone-100.png";
import email from "../../assets/images/contact/contact-icons/icons8-email-100.png";
import address from "../../assets/images/contact/contact-icons/icons8-location-100.png";

const ContactTwo = () => {
  const contactDetails = [
    {
      icon: phone,
      text: "+64 21 053 6486",
    },
    {
      icon: email,
      text: "hello@mindlogics.co.nz",
    },
    // {
    //   icon: address,
    //   text: "2/13 Richards Avenue, Forrest hill",
    // },
  ];
  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">Contact Us.</h2>
              <p className="description">
                Get in touch today to explore how we can elevate your digital
                journey.
              </p>
              <div className="contact-list-section">
                {contactDetails.map((val, i) => (
                  <li key={i} className="contact-list-item">
                    <div className="row">
                      <div className="col-1">
                        <img src={val.icon} className="contact-icon" />
                      </div>
                      <div className="col">
                        <p>{val.text}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </div>
            </div>
            <div className="form-wrapper">
              <ContactForm />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <img src={contact} alt="trydo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactTwo;
